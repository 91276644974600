import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axiosInstance from "../utils/axiosInstance";

const CandlestickChart = ( {stockSymbol}) => {
const [responseData,setResponseData] = useState([]);
  useEffect(() => {
    fetchGraphData()
  }, [stockSymbol]);
  const fetchGraphData = async () => {
    try {
      const response = await axiosInstance.get(`/finance-chart-yf2/${stockSymbol}`);
  
      if (response.status === 200) {
        setResponseData(response.data); 
      }
    } catch (error) {
      console.error('Error fetching subscription status', error);
    }
  };
const formatDataForCandlestick = (data) => {
  return data.map((item) => ({
    x: new Date(item.date).getTime(),
    y: [item.open, item.high, item.low, item.close],
  }));
};
const formattedData = responseData && responseData.length > 0 ? formatDataForCandlestick(responseData) : [];

  const options = {
    chart: {
      type: "line",
      height: 350,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div className="pt-8">
       {responseData && responseData.length > 0 && (
          <Chart
            type="line"
            height={400}
            options={options}
            series={[{ name:"price", data: formattedData }]}
          />
        )}
    </div>
  );
};

export default CandlestickChart;
