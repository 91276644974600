import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import { json } from 'react-router-dom'
import axiosInstance from '../utils/axiosInstance'
import Table from "./Table";

function Portfolio() {
 
  const headings = ["株", "株価", "変動 ", "時価総額"];
  const [pList, setPList] = useState({})
  const [tableData, setTableData] = useState({})
  useEffect(()=>{
    const fetchData = async () => {
      const response = await axiosInstance.post(`/overview_bulk`,{stockSymbols:pList.tracks} )
      setTableData(response.data)
    }
    fetchData()
  }
  ,[pList])

  useEffect(async ()=>{
    await axiosInstance.get(`/get_subscribe_email/${localStorage.getItem("email")}`).then(res=>{
      setPList(res.data);

    }).catch(err=>{
      console.log(err)
    })
  },[]);

  return (
    <div className='flex flex-col justify-center items-center'>
      <Nav/>
      <div className='mt-40 flex justify-center mx-5 p-5 items-center'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1}  stroke="currentColor" className="w-[7em] h-[7em] border-1 rounded-full p-4 shadow-lg text-indigo-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
</svg>
      
      <div className='ml-5'>
          <h1 className="font-bold text-2xl ">{localStorage.getItem("username")}</h1>
          <h1 className="font-semibold text-xl text-gray-500">{localStorage.getItem("email")}</h1>
      </div>
      </div>
      <div className=' flex  flex-col justify-center items-center flex-co w-fit rounded-2xl p-10  shadow-sm bg-gray-50 mt-10'>
        <div className="bg-gray-50 rounded-2xl p-4 min-w-[40em]
         min-h-[20em]">
          <p className="font-semibold text-xl px-6 py-4">
          予測リスト
          </p>
          {Object.keys(tableData).length>0?(<Table headings={headings} data={tableData} />):(<p className='px-6 text-center  pt-20 py-4 '>No prediction added</p>)}
          </div>
        

           {/* {pList.tracks && pList.tracks.map((item, i)=>(
            <li key={i}> <span className='font-semibold text-2xl'> {item} </span></li>
           ))} */}
      </div>

    </div>
  )
}

export default Portfolio
