import React, { useEffect, useRef, useState, useContext } from "react";
import Nav from "./Nav";
import listOfstocks from "../utils/listOfStocks.js.js";
import ClipLoader from "react-spinners/ClipLoader";
import StockContext from "../context/StockContext";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/helpers/FormatNumber";
import axiosInstance from "../utils/axiosInstance";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Disclaimer from "./Disclaimer";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Explore = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [details, setDetails] = useState([]);
  let [loading, setLoading] = useState(true);
  const { setStockSymbol } = useContext(StockContext);

  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  const itemsPerPage = 10;

  const data = listOfstocks;

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getStocks();
  }, [currentPage]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentData = data.slice(startIndex, endIndex);

  const getStocks = async () => {
    const stocks = [];

    for (let i = 0; i < currentData.length; i++) {
      try {
        const result = await axiosInstance.get(`/overview/${currentData[i]}`);
        stocks.push(result.data);
      } catch (error) {
        console.log("error", error);
      }
    }

    setDetails(stocks);
    setLoading(false);
  };

  const handleButtonClick = (item) => {
    // Set the StockSymbol in the context
    setStockSymbol(item);

    // Clear any necessary data using the clear function

    // Navigate to a different route (assuming you are using Gatsby)
    navigate("/");
  };

  return (
    <div>
      <Nav />

      <div style={{ marginTop: "20px" }}>
        <div
          class="border-b-3 border-blue-500 rounded-md border bg-blue-100 p-4 flex items-center mt-6 mx-8 xs:ml-0"
          style={{ marginTop: "100px" }}
          role="alert"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
            class="h-4 w-4 flex-shrink-0 stroke-[2.25] h-5 w-5 text-blue-600 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            ></path>
          </svg>
          <span class="font-medium"></span>
          <Disclaimer />
        </div>

        <table
          className="w-full text-sm text-left text-gray-500 "
          style={{ marginTop: "1rem" }}
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3 text-center">
                銘柄記号
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                名前
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                価格
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                変動
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                変動率 (%)
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                出来高
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                平均出来高
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                時価総額
              </th>
            </tr>
          </thead>
          <tbody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader
                color="#00008B"
                loading={loading}
                override={override}
                size={30}
                aria-label="Loading Spinner"
                data-testid="load er"
              />
            </div>
            {!loading &&
              details.map((item) => (
                <tr
                  className="bg-white border-b hover:bg-gray-50 text-center"
                  key={item.price.symbol}
                >
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {item.price.symbol}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    <button
                      style={{ color: "#409ffc", fontWeight: "bold" }}
                      onClick={() => handleButtonClick(item.price.symbol)}
                    >
                      {item.price.name}
                    </button>
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {item.currencySymbol} {item.price.price}
                  </td>
                  {item.regularMarketChange > 0 ? (
                    <td style={{ color: "#16c784", fontWeight: "bold" }}>
                      +{item.price.changesPercentage}
                    </td>
                  ) : (
                    <td style={{ color: "red" }}>
                      {item.price.changesPercentage}
                    </td>
                  )}
                  {item.regularMarketChangePercent * 100 > 0 ? (
                    <td style={{ color: "#16c784", fontWeight: "bold" }}>
                      +{(item.regularMarketChangePercent * 100).toFixed(2)} %
                    </td>
                  ) : (
                    <td style={{ color: "red" }}>
                      {(item.price.priceAvg50 * 100).toFixed(2)} %
                    </td>
                  )}
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {formatNumber(item.price.volume)}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {formatNumber(item.price.avgVolume)}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {formatNumber(item.price.marketCap)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <button
            style={{ marginRight: "5px" }}
            class="p-1.5 bg-indigo-500 rounded-3xl hover:bg-indigo-600 focus:outline-none"
            onClick={() => {
              handlePageChange(currentPage - 1);
              setLoading(true);
            }}
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon className="h-4 w-4 fill-gray-100" />
          </button>
          <p>
            ページ {currentPage}/{totalPages}
          </p>
          <button
            style={{ marginLeft: "6px" }}
            className="p-1.5 bg-indigo-500 rounded-3xl hover:bg-indigo-600 focus:outline-none"
            onClick={() => {
              handlePageChange(currentPage + 1);
              setLoading(true);
            }}
            disabled={currentPage === totalPages}
          >
            <ChevronRightIcon className="h-4 w-4 fill-gray-100" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Explore;
