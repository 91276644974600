import React, { useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import { TrendingDownIcon, TrendingUpIcon } from "@heroicons/react/solid";
import { toast } from "react-hot-toast";
import axiosInstance from "../utils/axiosInstance";

const Header = ({ name, country, symbol }) => {
  const [check, setCheck] = useState(false);
  const [longtermcheck, setLongTermCheck] = useState(false);
  const [price, setPrice] = useState({});
  const [priceVisibility, setPriceVisibility] = useState("hidden");

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/nishaaanth2/sample-repo/master/mock.json"
    )
      .then((res) => res.json())
      .then((json) => {
        setPrice(json);
      });
  }, []);
  useEffect(() => {
    fetchSubscriptionStatus();
    fetchSubscriptionStatusLong();
  }, [name]);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await axiosInstance.post("/subscribe_by_email", {
        email: localStorage.getItem("email"),
        symbol: symbol,
      });

      if (response.status === 200) {
        setCheck(response.data.status);

      }
    } catch (error) {
      console.error("Error fetching subscription status", error);
    }
  };

  const fetchSubscriptionStatusLong = async () => {
    try {
      const response = await axiosInstance.post("/subscribe_by_email_long", {
        email: localStorage.getItem("email"),
        symbol: symbol,
      });

      if (response.status === 200) {
        setLongTermCheck(response.data.status); // Set check based on response status
      }
    } catch (error) {
      console.error("Error fetching subscription status", error);
    }
  };

  const fireAPI = async (e) => {
    if (e) {
      setPriceVisibility("visible");
      try {
        const response = await axiosInstance.post("/subscribe", {
          symbol,
          country,
          email: localStorage.getItem("email"),
          status: true,
        });
        if (response.status === 200) {
          // Perform any additional actions upon successful registration
          toast.success("Subscribed");
        }
      } catch (error) {
        console.error("Registration error", error);
        // toast.error('Registration failed. Please try again.');
      }
    } else {
      setPriceVisibility("hidden");
      try {
        const response = await axiosInstance.post("/subscribe", {
          symbol,
          country,
          email: localStorage.getItem("email"),
          status: false,
        });
        if (response.status === 200) {
          console.log("Registration successful");
          // Perform any additional actions upon successful registration
          toast.success("Unsubscribed");
        }
      } catch (error) {
        console.error("Registration error", error);
        // toast.error('Registration failed. Please try again.');
      }
    }
  };

  const longterm = async (e) => {
    if (e) {
      setPriceVisibility("visible");
      try {
        const response = await axiosInstance.post("/subscribe_long", {
          symbol,
          country,
          longpredictionemail: localStorage.getItem("email"),
          status: true,
        });
        if (response.status === 200) {
          // Perform any additional actions upon successful registration
          toast.success("Subscribed");
        }
      } catch (error) {
        console.error("Registration error", error);
        // toast.error('Registration failed. Please try again.');
      }
    } else {
      setPriceVisibility("hidden");
      try {
        const response = await axiosInstance.post("/subscribe_long", {
          symbol,
          country,
          longpredictionemail: localStorage.getItem("email"),
          status: false,
        });
        if (response.status === 200) {
          console.log("Registration successful");
          // Perform any additional actions upon successful registration
          toast.success("Unsubscribed");
        }
      } catch (error) {
        console.error("Registration error", error);
        // toast.error('Registration failed. Please try again.');
      }
    }
  };

  return (
    <div className="px-6 flex ">
      <h1 className="text-xl md:text-3xl font-semibold py-1">{name}</h1>
      {name && (
        <div className="flex items-center ml-10 py-1.5 pl-5 pr-4 rounded-3xl font-semibold bg-blue-200">
          予測
          <ReactSwitch
            className=" pl-4 font-semibold"
            checkedIcon={null}
            uncheckedIcon={null}
            checked={check}
            onChange={(e) => {
              setCheck(e);
              fireAPI(e);
            }}
          />
        </div>
      )}
      {name && (
        <div className="flex items-center ml-10 py-1.5 pl-5 pr-4 rounded-3xl font-semibold bg-blue-200">
          LongTerm
          <ReactSwitch
            className=" pl-4 font-semibold"
            checkedIcon={null}
            uncheckedIcon={null}
            checked={longtermcheck}
            onChange={(e) => {
              setLongTermCheck(e);
              longterm(e);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
