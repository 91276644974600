import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import Card from "./Card";

const Details = ({ details }) => {
  const { darkMode } = useContext(ThemeContext);

  const detailsList = {
    name: "Name",
    country: "Country",
    currency: "Currency",
    exchange: "取引所（銘柄のシンボル）",
    ipo: "IPO Date",
    marketCapitalization: "Market Capitalization",
    finnhubIndustry: "Industry",
    quoteType: "引用タイプ",
    exchangeName: "取引所名",
    marketCap: "時価総額",
    regularMarketVolume: "出来高",
    regularMarketPreviousClose: "前日終値（最後に購入された株の金額）"
  };

  const convertMillionToBillion = (number) => {
    return (number / 1000).toFixed(2);
  };

  return (
    <Card>
      <ul
        className={`w-full h-full flex flex-col justify-between divide-y-1 ${
          darkMode ? "divide-gray-800" : null
        }`}
      >
        {Object.keys(details).map((item) => {
          return (
            <li key={item} className="flex-1 flex flex-col justify-between ">
              <span className="text-gray-400 py-0.5">{detailsList[item]}</span>
              <div className="py-0.5">
                  {details[item]}
              </div>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};

export default Details;
