import React from 'react';

const Disclaimer = () => {
  return (
    <marquee behavior="scroll" direction="left" scrollamount="4">
      免責事項: 株式市場への投資にはリスクが伴います。投資する前に、ご自身で調査し、財務アドバイザーに相談してください。ここで提供される情報は教育のみを目的としており、財務上のアドバイスとして考慮されるべきではありません。
    </marquee>
  );
};

export default Disclaimer;
