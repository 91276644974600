import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-hot-toast';
import Disclaimer from './Disclaimer';

const React = require("react");
const { useState } = require("react");
const { useForm } = require("react-hook-form");

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data) => {
    try {
      const response = await axiosInstance.post('/auth/login', data);
      // Handle successful login

      if (response.status === 200) {
        localStorage.setItem('isauthenticated', "true");
        localStorage.setItem('authentication', response.data.authentication);
        localStorage.setItem('_id', response.data._id);
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('username', response.data.username);
        localStorage.setItem('alpaca_key_id', response.data.alpaca_key_id);
        localStorage.setItem('alpaca_secret_key', response.data.alpaca_secret_key);
        // Redirect to your desired page
        window.location.href = "/";
        toast.success('Logged in successful!');

      }
    } catch (error) {
      console.error('Invalid email or password',error);
      toast.error('Login failed. Please try again.');
    }
  };

  return (
    <section className="bg-gray-50 min-h-screen flex items-center justify-center">
      <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
        <div className="md:flex md:w-1/2 px-8 md:px-16">
          <div className="md:w-3/3">
          <p className="text-xs mt-4 text-[#002D74]">Welcome to AI Yosoku,</p>

            <h2 className="font-bold mt-4 text-2xl text-[#002D74]">ログイン</h2>

            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <input
              type="email"
              name="email"
              placeholder="Eメール"
              className={`flex h-10 w-full border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2 mt-8 rounded-xl border ${
                errors.email ? 'border-red-500' : ''
              }`}
              {...register('email', { required: true })}
            />

            <input
              type="password"
              name="password"
              placeholder="パスワード"
              className={`flex h-10 w-full border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2 mt-3 rounded-xl border ${
                errors.password ? 'border-red-500' : ''
              }`}
              {...register('password', { required: true })}
            />

              <button
                className="bg-[#002D74] rounded-xl text-white py-2 hover:bg-[#002046] hover:scale-104 duration-300"
                type="submit"
              >
                ログイン
              </button>
            </form>
            <div className="mt-5 text-xs border-b border-[#002D74] py-4 text-[#002D74]">
              {/* <a href="#">Forgot your password?</a> */}
            </div>

            <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
              <p>アカウントをお持ちでない場合は、&nbsp;&nbsp;</p>
              <a href='/register'>
              <button
                className="py-2 px-5 bg-white border rounded-xl hover:scale-104 duration-300"
                onClick={() => console.log('Navigate to register page')}
              >
                登録する
              </button>
              </a>
            </div>
            <div className="mt-3 text-xs">
              <Disclaimer/>
            </div>
          </div>
        </div>

        <div className="hidden md:block md:w-1/2 relative">
          <img
            className="rounded-2xl"
            src="https://images.unsplash.com/photo-1621264448270-9ef00e88a935?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=957&q=80"
            alt="Login Image"
          />
          <p className="absolute bottom-2 right-2 text-white text-sm font-bold font-montserrat">@AI Yosoku</p>
        </div>
      </div>
    </section>
  );
};

export default Login ;
