import { useCookies } from 'react-cookie';

const useClearUserData = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const clearUserData = () => {
    localStorage.removeItem('isauthenticated');
    localStorage.removeItem('authentication');
    localStorage.removeItem('_id');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    localStorage.removeItem('alpaca_key_id');
    localStorage.removeItem('alpaca_secret_key');
    removeCookie('ANTONIO-AUTH');
    window.location.href = '/login'; // Navigate to the login page
  };

  return clearUserData;
};

export default useClearUserData;