export function formatNumber(number) {
  if (number >= 1000000000000) {
    return (number / 1000000000000).toFixed(2) + "兆円";
  } else if (number >= 1000000000) {
    return (number / 1000000000).toFixed(2) + " B";
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(2) + "百万株";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(2) + " K";
  } else {
    return number;
  }
}
