import React, { useState, useEffect, useContext } from "react";
import Header from "./Header";
import Overview from "./Overview";
import StockContext from "../context/StockContext";
import Nav from "./Nav";
import ApexCandleStick from "./ApexCandleStick";
import Table from "./Table";
import HalfDetails from "./HalfDetails";
import axiosInstance from "../utils/axiosInstance";
import { formatNumber } from "../utils/helpers/FormatNumber";
import Disclaimer from "./Disclaimer";

const Dashboard = () => {
  const headings = ["株", "価格", "価格の変動", "時価総額"];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.post(`/overview_bulk`, {
          stockSymbols: [
            "7203.T",
            "6758.T",
            "6752.T",
            "7974.T",
            "8306.T",
            "7267.T",
          ],
        });
        const data = Object.values(response.data);
        setTableData(data);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };
    fetchData();
  }, []);

  const { stockSymbol } = useContext(StockContext);

  const [stockDetails, setStockDetails] = useState({});
  const [quote, setQuote] = useState({});

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isauthenticated");

    if (isAuthenticated === null || isAuthenticated === "false") {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    const updateStockDetails = async () => {
      try {
        const result1 = await axiosInstance.get(`/overview/${stockSymbol}`);
        setStockDetails(result1.data.price);
      } catch (error) {
        setStockDetails({});
        console.error(error);
      }
    };
    updateStockDetails();
  }, [stockSymbol]);

  return (
    <div>
      <Nav />
      <div className="pt-32 md:pt-24 flex flex-col md:flex-row">
        <div className="md:w-2/3 py-4 px-4 md:px-12">
          <Header
            name={stockDetails?.name}
            country={stockDetails?.currency}
            symbol={stockSymbol}
          />
          <ApexCandleStick stockSymbol={stockSymbol} />
          <div className="flex rounded-md bg-gray-50">
            <div className="flex-1">
              <Overview
                symbol={stockSymbol}
                price={stockDetails?.regularMarketPrice}
                change={
                  stockDetails?.changesPercentage
                    ? stockDetails?.changesPercentage.toPrecision(3)
                    : 0
                }
                changePercent={
                  stockDetails?.changesPercentage
                    ? stockDetails?.changesPercentage.toPrecision(3)
                    : 0
                }
                currency={stockDetails?.currency}
              />
            </div>
            <div className="flex-1">
              <HalfDetails
                details={{
                  exchange: stockDetails?.name,
                  exchangeName: stockDetails?.exchange,
                  quoteType: stockDetails?.quoteType,
                }}
              />
            </div>
            <div className="flex-1">
              <HalfDetails
                details={{
                  marketCap: stockDetails?.marketCap
                    ? formatNumber(stockDetails.marketCap)
                    : "",
                  regularMarketVolume: stockDetails?.volume
                    ? formatNumber(stockDetails?.volume)
                    : "",
                  regularMarketPreviousClose: stockDetails?.previousClose,
                }}
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/3 p-4">
          <div className="bg-gray-50 rounded-2xl p-4">
            <p className="font-semibold text-xl px-6 py-4">
              他の人も注目している（トレンド株）
            </p>
            <Table headings={headings} data={tableData} />
          </div>
        </div>
      </div>
      <div className="mt-3 text-xs">
        <Disclaimer />
      </div>
    </div>
  );
};

export default Dashboard;
