import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "./Button.js";
import Search from "./Search";
import axiosInstance from "../utils/axiosInstance.js";
import useClearUserData from "../utils/helpers/ClearUserData"; 
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import StockContext from "../context/StockContext";


const Nav = () => {
  const clearUserData = useClearUserData(); // Use the custom hook
  const Links = [
    { name: "他の株を調べる", link: "/explore" },
    { name: "ポートフォリオ", link: "/portfolio" },
    { name: "あなたの株の履歴", link: "/history" },
    {name : "仮想取引", link: "/paper-trading"}
  ];
  const prevDropdownOpenRef = useRef(false);

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  const { setStockSymbol } = useContext(StockContext);


  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownToggle = () => setDropdownOpen(!dropdownOpen);
  const [notifications, setNotifications] = useState([]);
  const [hasUnreadNotifications,sethasUnreadNotifications] = useState(false);
  useEffect(() => {
    const fetchNotifications = async () => {

      try {
        const response = await axiosInstance.get(`/get_notification/`+localStorage.getItem('email'));
        if (response.status === 200) {
          setNotifications(response.data);
          sethasUnreadNotifications(response.data.some(notification => !notification.read));
        }
      } catch (error) {
        // console.error('Notification error', error);
        toast.loading("The user has logedin somewhere else")
        clearUserData();
      }
    };

    fetchNotifications();
    

    // Fetch notifications every 5 seconds (5000 milliseconds)
    const interval = setInterval(fetchNotifications, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(async () => {
    if (prevDropdownOpenRef.current && !dropdownOpen) {
      const notificationIds = notifications
      .filter(notification => !notification.read)
      .map(notification => notification.notificationId);
      
      try {
        const response = await axiosInstance.patch('/update_notification/'+localStorage.getItem('email'), {notificationIds});
        if (response.status === 200) {
          console.log('successful');
          // Perform any additional actions upon successful registration
        }
      } catch (error) {
        console.error('error', error);
      }
    }
    prevDropdownOpenRef.current = dropdownOpen;
  }, [dropdownOpen]);

  useEffect(() => {
    // Access localStorage only on the client side
    setUser({
      _id: localStorage.getItem('_id'),
      email: localStorage.getItem('email'),
      username: localStorage.getItem('username'),
      alpaca_key_id: localStorage.getItem('alpaca_key_id'),
      alpaca_secret_key: localStorage.getItem('alpaca_secret_key'),
    });
  }, []);

  const linkItems = Links.map(link => (
    <li className="md:mr-8 text-lg md:my-0 my-7" key={link.name}>
      <a href={link.link} className="text-gray-800 pl-0 pr-0 hover:text-gray-400 duration-500">
        {link.name}
      </a>
    </li>
  ));

  let userItems = [];
  if (user) {
    userItems = (
      <li className="md:ml-8 text-l md:my-0 my-7 flex md:bg-gray-200 rounded-2xl">
        <span className="text-gray-800 flex items-center pl-4 py-1 pr-2 rounded-l-2xl">
          {user.username}
        </span>
        <Button />
      </li>
    );
  }
  const notiRef = useRef(null)
  useEffect(() => {
    function handleClickOutside(event) {
      if (notiRef.current && !notiRef.current.contains(event.target)) {
        dropdownToggle()
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const nav = useNavigate()
  const moveDashboard = ()=>{
    nav("/")
  }

  const notificationItems = notifications.map((notification,index) => (
    <div ref={notiRef} key={notification.notificationId} className={`hover:bg-gray-100 ${notification.read ? '' : 'relative'}
      ${index < notifications.length - 1 ? 'border-b border-gray-100'  : ''}`} onClick={()=>moveToDashWithSymbol(notification.title)}>
      {/* Add the vertical line if the notification is unread */}
      {!notification.read && <div className="absolute left-0 h-full w-1 bg-yellow-200"></div>}
      
      <div className="block px-4 py-2 text-sm text-gray-800 font-semibold">
        {notification.title}
      </div>
      <div className="px-4 pb-2 text-xs text-gray-600">{notification.content}</div>
    </div>
  ));
  const moveToDashWithSymbol = (symbol)=>{
    const match = symbol.match(/\((.*?)\)/)
    if(match){
    setStockSymbol(match[1])
    dropdownToggle()
    nav("/")
    }else{
      console.log("Invalid symbol")
    }
  }
  return (
    <div className="shadow-sm w-full fixed top-0 left-0 z-50">
      <div className={`md:flex items-center justify-between bg-white py-4 md:px-10 px-7`}>
        <div className="font-bold text-2xl cursor-pointer flex items-center text-gray-800" onClick={moveDashboard}>
        <span className="text-3xl">AI&nbsp;</span>予測
        </div>
          <Search />
        
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px">
            <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
          </svg>
        </div>
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white z-10 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-15" : "top-[-490px]"
          }`}
        >
          {linkItems}

          {/* popper under this icon */}
          <div className="relative inline-block">
          <button
            onClick={dropdownToggle}
            className="relative inline-flex items-center p-2 text-sm font-medium text-center text-white bg-black-300 rounded-3xl bg-indigo-500 hover:bg-indigo-700"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg>
          <span className="sr-only">Notifications</span>
          
          {hasUnreadNotifications && (
            <div className="absolute inline-flex items-center justify-center w-3.5 h-3.5 text-xs font-bold text-white bg-yellow-400 rounded-full -top-1 -right-0">
            </div>
          )}
          </button>

          {/* Dropdown Content */}
          {dropdownOpen && (
            <div className="origin-top-right absolute w-72 right-0 mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
              <div className="py-1 max-h-60 overflow-y-auto">
                {notificationItems.length > 0 ? (
                  notificationItems
                ) : (
                  <p className="px-4 py-2 text-gray-800">No notifications</p>
                )}
              </div>
            </div>
          )}
        </div>

          {userItems}
        </ul>
      </div>
    </div>
  );
};

export default Nav;
