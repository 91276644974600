import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import useClearUserData from "../utils/helpers/ClearUserData"; 

const Button = (props) => {
  const clearUserData = useClearUserData(); // Use the custom hook

  return (
    <button
      onClick={() => {
        clearUserData();
      }}
      className="bg-red-500 text-white py-2 px-2 rounded-full hover:bg-red-300 duration-500"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
      </svg>
    </button>
  );
};

export default Button;