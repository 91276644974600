import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-hot-toast';
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const Register = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data) => {
    try {
      const response = await axiosInstance.post('/auth/register', data);
      if (response.status === 200) {
        console.log('Registration successful');
        // Perform any additional actions upon successful registration
        toast.success('Registration successful!');

        window.location.href="/login"
      }
    } catch (error) {
      console.error('Registration error', error);
      toast.error('Registration failed. Please try again.');

    }
  };

  return (
    <section className="bg-gray-50 min-h-screen flex items-center justify-center">
      <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
        <div className="md:flex md:w-1/2 px-8 md:px-16">
          <div className="md:w-3/3">
          <p className="text-xs mt-4 text-[#002D74]">Welcome to AI Yosoku,</p>
            <h2 className="font-bold mt-2 text-2xl text-[#002D74]">Register</h2>

            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <input
                type="text"
                name="username"
                placeholder="Username"
                className={`flex h-10 w-full border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2 mt-8 rounded-xl border ${
                  errors.username ? 'border-red-500' : ''
                }`}
                {...register('username', { required: true })}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className={`flex h-10 w-full border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2 mt-1 rounded-xl border ${
                  errors.email ? 'border-red-500' : ''
                }`}
                {...register('email', { required: true })}
              />
          

              <input
                type="password"
                name="password"
                placeholder="Password"
                className={`flex h-10 w-full border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2 mt-1 rounded-xl border ${
                  errors.password ? 'border-red-500' : ''
                }`}
                {...register('password', { required: true })}
              />
          

          <input
              type="text"
              name="alpaca_key_id"
              placeholder="Alpaca Key ID"
              className={`flex h-10 w-full border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2 mt-1 rounded-xl border ${
                errors.alpaca_key_id ? 'border-red-500' : ''
              }`}
              {...register('alpaca_key_id', { required: true })}
            />

            <input
              type="text"
              name="alpaca_secret_key"
              placeholder="Alpaca Secret Key"
              className={`flex h-10 w-full border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2 mt-1 rounded-xl border ${
                errors.alpaca_secret_key ? 'border-red-500' : ''
              }`}
              {...register('alpaca_secret_key', { required: true })}
            />

              <button
                className="bg-[#002D74] rounded-xl text-white py-2 hover:bg-[#002046] hover:scale-104 duration-300"
                type="submit"
              >
                Register
              </button>
            </form>
            <div className="mt-5 text-xs border-b border-[#002D74] py-4 text-[#002D74]">
              <a href="#">Forgot your password?</a>
            </div>

            <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
              <p>Already have an account?&nbsp;&nbsp;</p>
              <a href="/login" >
              <button
                className="py-2 px-5 bg-white border rounded-xl hover:scale-104 duration-300"
                onClick={() => console.log('Navigate to register page')}
              >
                Login
              </button>
              </a>
            </div>
          </div>
        </div>

        <div className="hidden md:block md:w-1/2 relative">
          <img
            className="rounded-2xl"
            src="https://images.unsplash.com/photo-1621264448270-9ef00e88a935?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=957&q=80"
            alt="Login Image"
          />
          <p className="absolute bottom-2 right-2 text-white text-sm font-bold font-montserrat">@AI Yosoku</p>
        </div>
      </div>
    </section>
  );
};

export default Register;
