import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Replace with your server's base URL
  withCredentials: true, // Include credentials (cookies) with the request

});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const sessionToken = localStorage.getItem('ANTONIO-AUTH'); // Assuming you store the session token in localStorage
//     if (sessionToken) {
//       config.headers['Authorization'] = `Bearer ${sessionToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
