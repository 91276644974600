import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import ThemeContext from "./context/ThemeContext";
import StockContext from "./context/StockContext";
import Register from "./components/Register";
import { Toaster } from 'react-hot-toast'; 
import Portfolio from "./components/Portfolio";
import History from "./components/History"
import Explore from "./components/Explore";
import Paper from "./components/Paper";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [stockSymbol, setStockSymbol] = useState("7203.T");
  
  return (
    <Router>
      <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
        <StockContext.Provider value={{ stockSymbol, setStockSymbol }}>
          <Routes>
            {/* <Route path="/" element={<DasLohboard />}> */}
              {/* <Route index element={<SignInForm />} /> */}
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/explore" element={<Explore />} />
              <Route path ="/portfolio" element={<Portfolio/>}/> 
              <Route path="/history" element={<History/>}/>
              <Route path="/paper-trading" element={<Paper/>}/>

            {/*
              <Route path="/continuewithphone" element={<ContWithPhone />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route
                path="/confirm-resetpassword"
                element={<ConfirmResetPassword />}
              />
            </Route>
            <Route path="/admin" element={<AdminLayout />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="settings" element={<AdminSettings />} />
              </Route>  */}
          </Routes>
          <Toaster position="top-right" />
        </StockContext.Provider>
      </ThemeContext.Provider>
    </Router>
  );
}

export default App;
