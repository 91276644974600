import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import axiosInstance from '../utils/axiosInstance';

function History() {
  const [history, setHistory] = useState([])
  const [filter, setFilter] = useState("")
  useEffect(async ()=>{
    await axiosInstance.get(`/get_notification/${localStorage.getItem('email')}`).then(res=>{
      setHistory(res.data);
    }).catch(err=>{
      console.log(err)
    })
  },[]);
  return (
    <div>
      <Nav />
      <div className='w-[90%] lg:w-[70%] mx-auto'>      
      
      <div className='flex justify-center my-40 mx-auto p-5 md:px-16 py-14 items-center bg-gray-100 rounded-2xl shadow-l flex-col'>
        <div className='flex w-full justify items-center  '>
        <h1 className=' font-semibold text-4xl '>履歴</h1>
    
        <select name="filter" id="filter" className= " w-32 h-10 rounded-md p-2 ml-auto focus:outline-none" onChange={(e)=> setFilter(e.target.value)}>
          <option value="">すべて</option>
          <option value="BUY">買う</option>
          <option value="SELL">売る</option>
        </select>
        </div>
      
           <ul className='flex flex-col pt-5'>
              
              {history.length>0 ? history.filter(data=> data["title"].includes(filter)).map(data=>(
                <li key={data["notificationId"]} className='flex items-center justify-between bg-white my-3 rounded-xl shadow-sm p-4'>
                  {!/BUY/.test(data["title"])?
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-600 mx-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                </svg>
                :<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-500 mx-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
</svg>}
                  <div className='w-[70%]'>
                    <h1 className=' font-bold text-xl'>{data["title"]}</h1>
                    <p>{data["content"]}</p>
                  </div>
                  <h3 className='mx-2 w-[20%] h-full overflow-auto'>{data["timestamp"]}</h3>
                  </li>
              )) : (<p className='my-40'>No History found</p>)}
            </ul>
      </div>
      </div>
    </div>
  )
}

export default History