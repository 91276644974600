import React, { useContext } from "react";
import StockContext from "../context/StockContext";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/helpers/FormatNumber";

const Table = ({ headings, data }) => {
  const { setStockSymbol } = useContext(StockContext);
  const navigate = useNavigate();

  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {headings.map((heading, index) => (
              <th key={index} scope="col" className="px-4 py-3 text-left">
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((stock, index) => (
            <tr
              key={index}
              onClick={() => {
                setStockSymbol(stock.symbol);
                navigate("/");
              }}
              className="cursor-pointer bg-white border-b hover:bg-gray-50"
            >
              <td
                scope="row"
                className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                {stock.symbol}
                <br />
                {stock.name.length > 20 ? `${stock.name.slice(0, 20)}...` : stock.name}
              </td>
              <td className="px-4 py-4">
                {stock.price}{stock.currency}
              </td>
              <td className="px-4 py-4">
                <span className={stock.changesPercentage < 0 ? "text-red-500" : "text-green-500"}>
                  {stock.changesPercentage.toFixed(2)}%
                </span>
                <br />
                {stock.change}
              </td>
              <td className="px-4 py-4">
                {formatNumber(stock.marketCap)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
