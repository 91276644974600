import React, { useEffect, useState } from 'react'

import toast from 'react-hot-toast';
import { AlpacaClient, AlpacaStream } from '@master-chief/alpaca';
import Nav from './Nav';

const Paper = () => {
  const [buyingPower, setBuyingPower] = useState(null)
  const [cash, setCash] = useState(null)
  const [symbol, setSymbol] = useState('')
  const [quantity, setQuantity] = useState('')
  const [tabindex, settabIndex] = useState(0)
  const [buy, setSell] = useState('buy')
  const [orders, setOrders] = useState([])
  const [positions, setPosition] = useState([])
  const apiKey = localStorage.getItem('alpaca_key_id')
  const apiSecret = localStorage.getItem('alpaca_secret_key')

  const alpaca = new AlpacaClient({
    credentials: {
      key: apiKey,
      secret: apiSecret,
    },
  });


  const fetchOrders = async () => {
    try {
      const fetchedOrders = await alpaca.getOrders({ limit: 10, status: 'all' });
      setOrders(fetchedOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };


  const fetchPositions = async () => {
    try {
      const response = await fetch('https://paper-api.alpaca.markets/v2/positions', {
        method: 'GET',
        headers: {
          'APCA-API-KEY-ID': apiKey,
          'APCA-API-SECRET-KEY': apiSecret,
        },
      });

      if (response.ok) {
        const positions = await response.json();
        setPosition(positions);
        console.log('Fetched Positions:', positions);
      } else {
        console.error('Failed to fetch positions:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching Positions:', error);
    }
  };

  useEffect(() => {
    fetchPositions();
    fetchOrders();
  }, []);

  alpaca.getAccount().then((account) => {
    const { buying_power, cash } = account;
    setBuyingPower(buying_power);
    setCash(cash);
  }).catch((error) => {
    console.error('Error fetching buying power:', error);
  });

  const handleReviewOrder = async () => {
    try {
      const order = await alpaca.placeOrder({
        symbol: symbol,
        qty: parseInt(quantity),
        side: buy,
        type: 'market',
        time_in_force: 'day',
      });

      if (order.status === 'accepted') {
        // Notify success on a 200 response
        toast.success('Order placed successfully');
      } else if (order.status === 'pending_new') {
        // Notify any other status as an error
        toast.success(`Order in queued`);
      }
      else {
        toast.error(`Order is not placed`)
      }
      fetchOrders();
    } catch (error) {
      console.error('Error placing order:', error);
      toast.error(`Error placing order: ${error.message}`);
    }
  };

  const handleChange = (e) => {
    setSymbol(e.target.value);
    console.log("symbol", symbol)
  };

  const handleQuantity = (e) => {
    setQuantity(e.target.value);
    console.log("symbol", quantity)
  };

  return (
    <div>
      <Nav />

      <div class="border-b-3 border-blue-500 rounded-md border bg-blue-100 p-4 flex items-center mt-10 mx-8 xs:ml-0" style={{ marginTop: "100px" }} role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-4 w-4 flex-shrink-0 stroke-[2.25] h-5 w-5 text-blue-600 mr-2"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path></svg>
        <span class="font-medium"></span> あなたは紙の取引を行っており、実際のお金は使用されていません。
これは米国株にのみ機能します
      </div>
      
      <div className=" flex flex-wrap">
      <div className="w-full sm:w-1/2 p-4">
          <div className="card p-8  md:mx-20 px-14 md:px-24 py-16 bg-blue-50 rounded-xl"  >
            <div className="space-x-4 ">
              <button
                onClick={() => settabIndex(0)}
                className={`py-2 px-4 rounded-lg ${tabindex === 0
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                  }`}
              >
                購入
              </button>
              <button
                onClick={() => {
                  settabIndex(1);
                  setSell("sell");
                }}
                className={`py-2 px-4 rounded-lg ${tabindex === 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                  }`}
              >
                売却
              </button>
            </div>
            <div className="mt-10 flex">
              <label className="w-full">銘柄記号 </label>
              <div className="space-x-2 flex justify-end">
                <input
                  className="border border-gray-300 rounded-md px-2 py-1 w-32"
                  type="text"
                  value={symbol}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex mt-4">
              <label className="w-full">数量</label>
              <div className="space-x-2 flex justify-end">
                <input
                  className="border border-gray-300 rounded-md px-2 py-1 w-32"
                  type="text"
                  value={quantity}
                  onChange={handleQuantity}
                  placeholder="ここに入力します...."
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                className="py-2 px-4 bg-blue-500 text-white rounded-lg"
                onClick={handleReviewOrder}
              >
                注文する
              </button>
            </div>

          </div>
          <div class="md:mx-20 px-14   p-6 rounded-lg  bg-gray-50  mt-4">
        <div class="flex flex-wrap">
          <div class="flex w-full justify-between border-b p-4 pl-0 md:block md:w-1/3 md:border-b-0"><div class="text-lg font-medium">購買力 </div><div class="md:mt-6 text-lg">  {buyingPower ? `$${buyingPower}` : 'Loading...'}</div></div>
          <div class="flex w-full justify-between border-b p-4 pl-0 md:block md:w-1/3 md:border-b-0"><div class="text-lg font-medium">現金</div><div class="md:mt-6 text-lg">  {cash ? `$${cash}` : 'Loading...'}</div></div>
          <div class="flex w-full justify-between border-b p-4 pl-0 md:block md:w-1/3 md:border-b-0"><div class="text-lg font-medium">日々の変動</div><div class="md:mt-6 text-lg">-</div></div>
        </div>
      </div>
        </div>







        <div className="w-full sm:w-1/2 p-4">
          <div class="space-y-6 p-6 rounded-lg bg-gray-50 mx-4 " style={{ width: 'fixed' }}>
            <div class="flex space-x-2 justify-between items-start">
              <p class="m-0 p-0 text-inherit text-lg font-medium">最近の注文 </p>
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-4 py-3 text-left">銘柄記号
                    </th>
                    <th scope="col" className="px-4 py-3 text-left">市場タイプ
                    </th>
                    <th scope="col" className="px-4 py-3 text-left">数量
                    </th>
                    <th scope="col" className="px-4 py-3 text-left">平均コスト
                    </th>
                    <th scope="col" className="px-4 py-3 text-left">総費用
                    </th>
                    <th scope="col" className="px-4 py-3 text-left">状態
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="px-4 py-3">{order.symbol}</td>
                      <td className="px-4 py-3">{order.type} {order.side} </td>
                      <td className="px-4 py-3">{order.qty}</td>
                      <td className="px-4 py-3">${order.filled_avg_price}</td>
                      <td className="px-4 py-3">${(order.qty * order.filled_avg_price).toFixed(2)}</td>
                      <td className="px-4 py-3">{order.status}</td>
                    </tr>
                  ))}

                </tbody>

              </table>
            </div>
          </div></div>

        
      </div>
     

      <div class="space-y-6 p-6 rounded-lg mx-8 mt-4 bg-gray-50">
        <div class="flex space-x-2 justify-between items-start">
          <p class="m-0 p-0 text-inherit text-lg font-medium">トップポジション</p>
        </div>

        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th scope="col" class="px-4 py-3 text-left">
              資産
              </th>
              <th scope="col" class="px-4 py-3 text-left">
              株価
              </th>
              <th scope="col" class="px-4 py-3 text-left">
              株式数
              </th>
              <th scope="col" class="px-4 py-3 text-left">
              市場価格
              </th>
              <th scope="col" class="px-4 py-3 text-left">
              合計利益/損失率($)
              </th>
            </tr>
          </thead>
          <tbody>
            {positions.map((position, index) => (
              <tr key={index}>
                <td class="px-6 py-4 whitespace-nowrap">{position.symbol}</td>
                <td class="px-6 py-4 whitespace-nowrap">${position.avg_entry_price}</td>
                <td class="px-6 py-4 whitespace-nowrap">{position.qty}</td>
                <td class="px-6 py-4 whitespace-nowrap">${position.market_value}</td>
                <td class="px-6 py-4 whitespace-nowrap">${position.unrealized_pl}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      
    </div>
  );
}
export default Paper